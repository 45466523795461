import React from "react";
import PropTypes from "prop-types";

const shapes = {
  square: "rounded-[0px]",
};
const variants = {
  fill: {
    white_A700:
      "bg-white-a700 text-gray-900 hover:bg-deep_purple-a100 hover:text-white-a700 transition ease-in-out duration-200",
    pink_400: "bg-pink-400 text-white-a700",
    teal_400: "bg-teal-400 text-white-a700",
    amber_300: "bg-amber-300 text-gray-900",
    indigo_500: "bg-indigo-500 text-white-a700",
  },
};
const sizes = {
  xs: "h-[42px] px-3 text-[20px]",
  md: "h-[126px] px-[34px] text-6xl  lg:h-[94px]  md:h-[50px]",
  sm: "h-[94px] px-[34px] text-6xl sm:h-[54px]",
};

const Button = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape,
  variant = "fill",
  size = "sm",
  color = "white_A700",
  ...restProps
}) => {
  return (
    <button
      className={`${className} flex flex-row items-center justify-center text-center cursor-pointer ${
        (shape && shapes[shape]) || ""
      } ${(size && sizes[size]) || ""} ${
        (variant && variants[variant]?.[color]) || ""
      }`}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
  shape: PropTypes.oneOf(["square"]),
  size: PropTypes.oneOf(["xs", "md", "sm"]),
  variant: PropTypes.oneOf(["fill"]),
  color: PropTypes.oneOf([
    "white_A700",
    "pink_400",
    "teal_400",
    "amber_300",
    "indigo_500",
  ]),
};

export { Button };
