import React, { useState } from "react";
import { Img, Text, Heading, Button } from "../components";
import Footer from "components/Footer/Footer";
import Header from "components/Header";
import { Link, useNavigate } from "react-router-dom";

export default function Faq() {
  const [activeIndex, setActiveIndex] = useState(null);
  const navigate = useNavigate();
  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <>
      <div className="w-full bg-[url(/public/images/arrow-yellow.png)] bg-arrow-green bg-no-repeat">
        <div className="w-full bg-[url(/public/images/arrow-green.png)] bg-arrow-green bg-no-repeat">
          <div className="container-xs mt-[84px] md:mt-[20px] pt-[60px] pl-20 pr-20 sm:pl-2 sm:pr-2 ">
            <h1 className="mainHeadingInner text-teal-400"> FAQs </h1>
            <div className="w-full mx-auto my-10">
              {[
                {
                  dote: (
                    <span
                      className="bulter"
                      style={{ background: "#2AA98B" }}
                    ></span>
                  ),
                  question: "Who can attend RIE 2025?",
                  answer: (
                    <span>
                      {" "}
                      RIE 2025 is open to all members of the Entrepreneur's
                      Organization. Alumni members and SLPs of EO members are
                      eligible to attend, only if they are a guest of a
                      registered EO member. Neither can attend without the
                      participation of an EO Member.
                    </span>
                  ),
                },
                {
                  dote: (
                    <span
                      className="bulter"
                      style={{ background: "#424FA0" }}
                    ></span>
                  ),
                  question: "What is the registration fee for RIE 2025?",
                  answer: (
                    <div>
                      <p className="mb-5">
                        Registration fee* for EO Members from South Asia are as
                        follows:
                      </p>
                      <ul className="listing-blueter">
                        <li>Member: INR 1,20,000 + GST</li>
                        <li>Spouse or Life Partner: INR 75,000 + GST</li>
                      </ul>

                      <p className="mb-5">
                        Registration fee* for Global EO Members (from outside
                        South Asia) are as follows:
                      </p>
                      <ul className="listing-blueter">
                        <li className="mb-5">Member: US$2500 + GST</li>
                        <li className="mb-5">
                          Spouse or Life Partner: US$1500 + GST
                        </li>
                      </ul>

                      <p className="mb-5">
                        There will also be an Early Bird slab announced. Stay
                        tuned! <br style={{ display: "block" }}></br>
                        *Please note that the above prices are for a certain
                        number of tickets in this slab and are subject to
                        increase in future.
                      </p>
                    </div>
                  ),
                },
                {
                  dote: (
                    <span
                      className="bulter"
                      style={{ background: "#EB3D71" }}
                    ></span>
                  ),
                  question: "What does the registration fee include?",
                  answer: (
                    <div>
                      <ul className="listing-blueter">
                        <li>The registration fee includes:</li>
                        <li> All learning events</li>
                        <li> All socials</li>
                        <li> All meals & drinks</li>
                        <li>All transfers</li>
                      </ul>
                      <p className="mb-5">
                        The registration fee does not include:
                      </p>
                      <ul className="listing-blueter red">
                        <li>Airfare</li>
                        <li>Visa fee</li>
                        <li>Accommodation </li>
                        <li>MyEO events</li>
                        <li>Any retreats pre or post RIE</li>
                      </ul>
                    </div>
                  ),
                },
                {
                  dote: (
                    <span
                      className="bulter"
                      style={{ background: "#ECC846" }}
                    ></span>
                  ),
                  question: "When do I have to pay the registration fee?",
                  answer:
                    "The full registration fee must be paid while registering for RIE 2025 on the website. Full payment is mandatory to confirm your seat at the event. Once the payment is completed, you will receive an immediate confirmation of your registration.",
                },

                {
                  dote: (
                    <span
                      className="bulter"
                      style={{ background: "#EB9570" }}
                    ></span>
                  ),
                  question: "What are the payment methods available?",
                  answer:
                    "You can pay using a valid credit or debit card, and as allowed by the respective payment gateways. We apologise in advance if your preferred payment method is not available.",
                },
                {
                  dote: (
                    <span
                      className="bulter"
                      style={{ background: "#EB3D71" }}
                    ></span>
                  ),
                  question: "What is the last date to register?",
                  answer:
                    "We have a limited number of seats at RIE 2025. Registrations will close as soon as the seats are sold out.",
                },
                {
                  dote: (
                    <span
                      className="bulter"
                      style={{ background: "#7047A5" }}
                    ></span>
                  ),
                  question: "What is the cancellation/transfer policy?",
                  answer: (
                    <ol className="alpha-view">
                      <li className="mb-5">
                        Registrations once confirmed cannot be cancelled.
                      </li>
                      <li className="mb-5">
                        However, in any eventuality, registrations can be
                        transferred to another member within the registered
                        member’s chapter up to 120 days before the event (i.e.
                        before September 11, 2024). It will be the member’s
                        responsibility to inform the RIE 2025 team of the
                        transfer at{" "}
                        <a
                          href="mailto:rie@eobangalore.com"
                          style={{ color: "#424ea0", display: "inline" }}
                        >
                          rie@eobangalore.com
                        </a>
                        .
                      </li>
                      <li className="mb-5">
                        The registration fee applicable at the time of the
                        transfer will apply to the new member. Any difference in
                        registration fee must be paid within the timeline
                        mentioned above, along with a transfer fee of Rs.
                        50,000.
                      </li>
                      <li className="mb-5">
                        Please read our{" "}
                        <Link
                          to="/cancellation-policy"
                          rel="noreferrer"
                          className="text-skyblue-500"
                          style={{
                            display: "inline",
                            color: "#424ea0",
                            paddingRight: "5px",
                          }}
                        >
                          Cancellation Policy
                        </Link>
                        for complete information.
                      </li>
                    </ol>
                  ),
                },
                {
                  dote: (
                    <span
                      className="bulter"
                      style={{ background: "#2AA98B" }}
                    ></span>
                  ),
                  question:
                    "When should I apply for a visa for my trip to India? What happens if I do not get a visa in time?",
                  answer: (
                    <ol className="alpha-view">
                      <li className="mb-5">
                        We urge all international attendees travelling to India
                        for RIE to apply for an Indian visa no later than
                        September 30, 2024.
                      </li>
                      <li className="mb-5">
                        If you are unable to obtain an Indian visa till the last
                        date of travel, we will refund the entire registration
                        fee to you, provided you are able to share evidence of
                        timely application for the visa as per the suggested
                        date (i.e. September 30, 2024).
                      </li>
                    </ol>
                  ),
                },
                {
                  dote: (
                    <span
                      className="bulter"
                      style={{ background: "#424FA0" }}
                    ></span>
                  ),
                  question:
                    "I am a new member. Can I use my Event Voucher for RIE?",
                  answer: (
                    <div>
                      <ol className="alpha-view">
                        <li className="mb-5">
                          Yes, you can use a valid Event Voucher for RIE 2025.
                          You will be prompted to enter the same at the time of
                          registration.
                          <br style={{ display: "block" }}></br>
                          Voucher redemption will be subject to verification
                          from EO Global and Terms & Conditions specified
                          therein.
                        </li>
                      </ol>
                    </div>
                  ),
                },
                {
                  dote: (
                    <span
                      className="bulter"
                      style={{ background: "#EB3D71" }}
                    ></span>
                  ),
                  question:
                    "Will I be staying at the same hotel where RIE 2025 is scheduled to happen?",
                  answer: (
                    <div>
                      {" "}
                      <p>
                        The main events of RIE will be held at{" "}
                        <a
                          target="_blank"
                          href="https://www.marriott.com/en-us/hotels/blrnh-jw-marriott-bengaluru-prestige-golfshire-resort-and-spa/overview/?gclid=Cj0KCQjwsuSzBhCLARIsAIcdLm4TMvFAaITa-utXTPz9QuLBZgi-Ln0ASigYwia1vySNKJuOM_nrYE4aAt5_EALw_wcB&gclsrc=aw.ds&cid=PAI_GLB00050CN_GLE000BLT8_GLF000ONUF"
                          style={{ color: "#424ea0", display: "inline" }}
                        >
                          JW Marriott Bengaluru Prestige Golfshire Resort & Spa.
                        </a>{" "}
                        We have blocked rooms at the same hotel and at the
                        nearby{" "}
                        <a
                          target="_blank"
                          href="https://www.marriott.com/en-us/hotels/blrtx-mulberry-shades-bengaluru-nandi-hills-a-tribute-portfolio-resort/overview/"
                          style={{ color: "#424ea0", display: "inline" }}
                        >
                          Mulberry Shades
                        </a>{" "}
                        Bengaluru. Details of both hotels as well as the booking
                        link at pre-negotiated rates will be available on the
                        website soon. <br style={{ display: "block" }}></br>
                        Please see the{" "}
                        <span
                          className="text-skyblue-500 cursor-pointer"
                          style={{
                            display: "inline",
                            color: "#424ea0",
                            paddingRight: "5px",
                          }}
                          onClick={() => {
                            navigate("/plan-your-trip", {
                              state: { divid: "accommodation" },
                            });
                          }}
                        >
                          Accommodations section
                        </span>
                        for more information.
                      </p>{" "}
                    </div>
                  ),
                },
                {
                  dote: (
                    <span
                      className="bulter"
                      style={{ background: "#ECC846" }}
                    ></span>
                  ),
                  question:
                    "Can the RIE 2025 team help me book my accommodations and flights to Bengaluru, and/or travel in India after RIE?",
                  answer: (
                    <div>
                      <ol className="alpha-view">
                        <li className="mb-5">
                          We are unable to handle flight and hotel bookings for
                          RIE attendees.{" "}
                        </li>
                        <li>
                          However, our Plan Your Retreat section for Chapter and
                          Forum Retreats in India before or after RIE will go
                          live shortly. Please email us at{" "}
                          <a
                            href="mailto:rie@eobangalore.com"
                            style={{ color: "#424ea0", display: "inline" }}
                          >
                            rie@eobangalore.com
                          </a>
                          with any specific requests and we will try our best to
                          guide you appropriately. Please see the{" "}
                          <span
                            className="cursor-pointer"
                            onClick={() => {
                              navigate("/plan-your-trip", {
                                state: { divid: "retreat" },
                              });
                            }}
                            style={{ color: "#424ea0", display: "inline" }}
                          >
                            Retreat Section{" "}
                          </span>{" "}
                          of Plan Your Trip for more information.
                        </li>
                      </ol>
                    </div>
                  ),
                },
                {
                  dote: (
                    <span
                      className="bulter"
                      style={{ background: "#EB9570" }}
                    ></span>
                  ),
                  question: "Where can I find the schedule for RIE 2025?",
                  answer: (
                    <ol className="alpha-view">
                      <li className="mb-5">
                        The detailed schedule will be available on the website
                        shortly. Stay tuned…
                      </li>
                    </ol>
                  ),
                },
              ].map((item, index) => (
                <div key={index} className=" mb-5 sm:mb-0">
                  <button
                    onClick={() => handleToggle(index)}
                    className=" p-4 text-left faq-width   flex  faqheading  "
                  >
                    {item.dote}
                    <span
                      style={{ flex: "1 1 30px" }}
                      className=" word-break  "
                    >
                      {" "}
                      {item.question}{" "}
                    </span>
                    <span
                      className={
                        activeIndex === index ? "transform rotate-180" : ""
                      }
                      style={{ marginLeft: "25px" }}
                    >
                      <svg
                        width="40"
                        height="28"
                        viewBox="0 0 40 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M16.5861 22.9529L31.3411 1.41211H1.83105L16.5861 22.9529Z"
                          stroke={activeIndex === index ? "#EB3D71" : "#2AA98B"}
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M24.0138 26.5879L38.7689 5.04715L9.25879 5.04712L24.0138 26.5879Z"
                          stroke={activeIndex === index ? "#F3836F" : "#ECC846"}
                        />
                      </svg>

                      {/* <svg
                    width="40"
                    height="28"
                    viewBox="0 0 40 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd" 
                      clip-rule="evenodd"
                      d="M24.0136 5.04712L9.25848 26.5879L38.7686 26.5879L24.0136 5.04712Z"
                      stroke={activeIndex === index ? "#EB3D71" : "#2AA98B"}
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M16.5858 1.41208L1.83075 22.9529L31.3408 22.9529L16.5858 1.41208Z"
                      stroke={activeIndex === index ? "#EB3D71" : "#2AA98B"}
                    />
                  </svg> */}
                    </span>
                  </button>
                  {activeIndex === index && (
                    <div className="p-4 ">
                      <p className="pl-9 md:pl-6  sm:pl-9 subheadingInner ">
                        {item.answer}
                      </p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
