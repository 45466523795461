import React, { useState } from "react";
import { Img, Text, Heading, Button } from "../components";
import Footer from "components/Footer/Footer";
import Header from "components/Header";
import { Link, useNavigate } from "react-router-dom";

export default function Sustainability() {
  const [activeIndex, setActiveIndex] = useState(null);
  const navigate = useNavigate();
  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <>
      <div className="w-full bg-[url(/public/images/arrow-yellow.png)] bg-arrow-green bg-no-repeat">
        <div className="w-full bg-[url(/public/images/arrow-green.png)] bg-arrow-green bg-no-repeat">
          <div className="container-xs mt-[84px] md:mt-[20px] pt-[60px] pl-20 pr-20 sm:pl-5 sm:pr-5 ">
            <h1 className="mainHeadingInner text-teal-400">
              Sustainability at RIEMIX
            </h1>
            <p className="subheadingInner sm:mb-5 sm:mt-5">
              At EO Bangalore, we are tackling the challenge of climate change
              head-on. Large events like RIE come with inevitable emissions—from
              travel to materials and waste—but we are committed to changing the
              game at RIEMIX by integrating sustainable practices into its
              planning and execution, measuring our impact and setting up
              mechanisms to offset them.
            </p>

            <p className="subheadingInner sm:mb-5">
              We hope to set a new standard for sustainability in our collective
              attempt to make RIEMIX a carbon neutral event. Our key goals are
              to:
            </p>
            <p className="subheadingInner sustainabilityListing flex items-center flex-direction-column sm:mt-5 ">
              <span className="mainHeadingInner text-red pr-2">Measure</span>
              the carbon footprint of RIEMIX.
            </p>
            <p className="subheadingInner sustainabilityListing flex items-center">
              <span className="mainHeadingInner text-red pr-2">Minimise</span>
              emissions by being mindful in our consumption and usage.
            </p>
            <p className="subheadingInner sustainabilityListing flex items-center">
              <span className="mainHeadingInner text-red pr-2">Offset</span>
              unavoidable emissions through credible carbon projects.
            </p>
            <p className="subheadingInner sustainabilityListing flex items-center">
              <span className="mainHeadingInner text-red pr-2">Inspire</span>
              attendees to take sustainable action in their personal and
              professional lives.
            </p>
            <h2 className="text-[60px] lg:text-[34px] md:text-[24px]  sm:text-[24px] mt-10 mb-20 md:mb-10 font-bold  text-teal-400 ">
              Components of RIEMIX Carbon Footprint Emissions
            </h2>

            <Img
              src={`${process.env.PUBLIC_URL}/images/footprint-emmission.png`}
              alt="play"
              className=" w-auto max-w-full h-auto"
            />

            <h2 className="text-[60px] lg:text-[34px] md:text-[24px]  md:text-[44px] sm:text-[24px] mt-20 mb-10 md:mb-10 sm:mt-10 sm:mb-5 font-bold  text-teal-400 ">
              Approach to Measuring and Reducing Impact
            </h2>
            <p className="subheadingInner">
              Our approach is built on six interconnected pillars, each playing
              a vital role in minimising the environmental impact of RIEMIX.
            </p>
            <Img
              src={`${process.env.PUBLIC_URL}/images/reducing-impact.png`}
              alt="play"
              className=" w-auto max-w-full h-auto mt-10 mb-10 md:mb-10"
            />

            <p className="subheadingInner sm:mb-5">
              From measuring the carbon footprint and offsetting emissions, to
              collaborating with vendors and empowering attendees, this
              framework ensures a comprehensive and transparent strategy for
              sustainable event management. Since data availability is a key
              challenge in carbon footprint calculations, we have started
              working with our partners and vendors early to gather
              comprehensive data and are adhering to the globally recognized ISO
              14064-1:2018 standard to measure carbon footprint.
            </p>

            <p className="subheadingInner">
              RIEMIX represents a step towards addressing the challenges of
              climate change through thoughtful and intentional action. Through
              measurable goals, collaborative efforts, and a commitment to
              inspire change, RIEMIX not only seeks to minimize its own
              environmental impact but also empowers attendees to offset their
              footprint and adopt sustainable practices for future events.
              Together, we can drive meaningful progress towards a greener
              future.
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
