import React from "react";

const sizes = {
  textxs: "text-2xl font-light lg:text-xl md:text-[22px]",
  texts: "text-[25px] font-light lg:text-[18px] md:text-[23px] sm:text-[21px]",
  textmd:
    "text-[32px] font-normal not-italic lg:text-[27px] md:text-3xl sm:text-[24px]",
};

const Text = ({
  children,
  className = "",
  as,
  size = "textxs",
  ...restProps
}) => {
  const Component = as || "p";

  return (
    <Component
      className={`text-deep_purple-a100 hover:text-white-a700 font-averta ${className} ${sizes[size]}`}
      {...restProps}
    >
      {children}
    </Component>
  );
};

export { Text };
