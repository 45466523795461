import KeynoteSession from "components/KeynoteSession";
import React, { Suspense } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
const keynoteSessionsList = [
  {
    keynoteText: "Keynote Sessions",
    className: "bg-pink-400",
    desc: "Open your mind to discovery and get inspired by innovative ideas. Hear from thought-leaders and visionaries who are driving change. ",
  },
  {
    keynoteText: (
      <>
        Epic
        <br />
        Socials
      </>
    ),
    className: "bg-amber-300 ",
    desc: "Enjoy memorable parties and social gatherings that blend fun with meaningful interactions. Socials that are sure to provide an energetic atmosphere where you can forge lasting connections while having a great time.",
  },
  {
    keynoteText: (
      <>
        MyEO
        <br />
        Experiences
      </>
    ),
    className: "bg-teal-400 ",
    desc: "Meet peers with shared passions and explore new interests. Take your RIE experience to the next level by participating in an array of MyEO activities curated specially for the diverse EO community.",
  },
];
export default function AboutSlide() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1.0,
          slidesToScroll: 1,
          infinite: false,
          arrows: true,
          dots: false,
        },
      },

      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.0,
          slidesToScroll: 1,
          arrows: true,
          dots: false,
          infinite: false,
        },
      },
    ],
  };
  return (
    <Suspense fallback={<div>Loading feed...</div>}>
      <Slider {...settings}>
        {keynoteSessionsList.map((d, index) => (
          <KeynoteSession
            {...d}
            key={"keynoteList" + index}
            headingClassName={`head-${index + 1}`}
          />
        ))}
      </Slider>
    </Suspense>
  );
}
