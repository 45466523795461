import React from "react";

const sizes = {
  headingxs: "text-2xl font-semibold lg:text-xl md:text-[22px]",
  headingxxs: "text-[20px] font-semibold lg:text-[20px] md:text-[20px]",
  headings:
    "text-[25px] font-semibold lg:text-[18px] md:text-[23px] sm:text-[21px]",
  headingmd: "text-3xl font-bold lg:text-[25px] md:text-[28px] sm:text-[26px]",
  headinglg:
    "text-[32px] font-extrabold lg:text-[24px]  md:text-3xl sm:text-[22px]",
  headingxl:
    "text-[50px] font-bold lg:text-[42px] md:text-[30px] sm:text-[40px]",
  heading2xl: "text-[70px] font-extrabold lg:text-[70px] md:text-5xl",
  heading3xl: "text-[84px] font-extrabold lg:text-[84px] md:text-5xl",
};

const Heading = ({
  children,
  className = "",
  size = "headingxl",
  as,
  ...restProps
}) => {
  const Component = as || "h6";

  return (
    <Component
      className={`text-white-a700  font-averta ${className} ${sizes[size]}`}
      {...restProps}
    >
      {children}
    </Component>
  );
};

export { Heading };
