import React, { useEffect, useRef } from "react";

const GoogleTranslate = () => {
  const translateRef = useRef(null);

  useEffect(() => {
    let count = 0;
    let intervalId;

    const translate = () => {
      if (window.google && window.google.translate) {
        clearInterval(intervalId);
        new window.google.translate.TranslateElement(
          {
            pageLanguage: "en",
          },
          translateRef.current
        );
      } else if (++count === 10) {
        clearInterval(intervalId);
      }
    };

    intervalId = setInterval(translate, 100);
    let d = translateRef.current;
    // Cleanup function
    return () => {
      clearInterval(intervalId);
      if (d) {
        d.innerHTML = ""; // Remove TranslateElement content
      }
    };
  }, []);

  return <div id="google_translate_element" ref={translateRef}></div>;
};

export default GoogleTranslate;
