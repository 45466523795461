import React, { useState } from "react";
import { Img, Text, Heading, Button } from "../components";
import Footer from "components/Footer/Footer";
import Header from "components/Header";

export default function CancellationPolicy() {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <>
      <div className="w-full bg-[url(/public/images/arrow-yellow.png)] bg-arrow-green bg-no-repeat">
        <div className="w-full bg-[url(/public/images/arrow-green.png)] bg-arrow-green bg-no-repeat">
          <div className="container-xs mt-[84px] md:mt-[20px] pt-[60px] gap-32 lg:gap-24 md:gap-24 sm:gap-16  pr-20 pl-5 pr-5 ">
            <h1 className="mainHeadingInner text-orange">
              {" "}
              TERMS & CONDITIONS{" "}
            </h1>
            <h2 className="text-[40px] md:text-[34px] sm:text-[24px] font-semibold  text-teal-400 text-purple mt-[50px] sm:mt-[10px]">
              {" "}
              CANCELLATION POLICY
            </h2>
            <div className=" mx-auto my-10 cencelpolicyMain">
              <div className="cencelpolicyHeading text-green">Payments</div>
              <div className="cencelpolicycontent">
                <p>
                  RIE 2025 is open to EO Members and their Spouses/Life
                  Partners. The registration fee for Members and/or their
                  Spouses/Life Partners includes: all educational sessions and
                  materials, all scheduled meals and receptions, scheduled
                  social activities and ground transportation during the event.
                  All other expenses are not covered by the registration fee,
                  including but not limited to: air transportation, hotel
                  accommodations, optional activities and personal incidentals.
                </p>
                <p>
                  Full payment of the registration fee is due at registration
                  without which a registration cannot be confirmed.
                </p>
                <p>
                  The EO Member must be in good standing at the time of the
                  event. If member status changes between the time of
                  registration and the start of the event, the cancellation
                  policy below applies.
                </p>
              </div>

              <div className="cencelpolicyHeading text-blue">Cancellations</div>
              <div className="cencelpolicycontent">
                <p>
                  The registration fee for RIE 2025 is non-refundable unless the
                  event is cancelled/postponed by the organisers due to any
                  government guidelines.
                </p>
                <p>
                  Ticket transfers are allowed on a case by case basis. It can
                  only be done within the Chapter that the registered member
                  belongs to. The request for transfer must be done by emailing{" "}
                  <a href="mailto:rie@eobangalore.com" className="text-blue">
                    {" "}
                    rie@eobangalore.com{" "}
                  </a>{" "}
                  no later than September 1, 2024. Any other means of
                  communication will be considered invalid and a request for
                  transfer post this date will not be accepted. A difference in
                  ticket value as per the applicable rate at the time of
                  transfer will be payable by the new attending member. Further,
                  an additional transfer fee of Rs.50,000 will be payable. The
                  ticket transfer will not be confirmed until the entire balance
                  is paid in full.
                </p>

                <p>
                  If a registered member cancels from an event, the space cannot
                  be sold/transferred.
                </p>
                <p>
                  If a member or guest fails to attend the event, it is
                  considered a cancellation and no refund will be administered.
                </p>
                <p>
                  Exceptions to the cancellation policy are only granted in the
                  case of a death in the immediate family (spouse or partner,
                  children, parents, siblings). The member/guest must provide
                  written documentation certifying their situation. Final
                  decision will be at the discretion of EO Bangalore.
                </p>
                <p>
                  EO Bangalore is not responsible for fees and/or charges paid
                  by event attendees to hotels, airlines, or any other third
                  party.
                </p>
                <p>
                  EO Bangalore recommends the purchase of travel insurance
                  whenever signing up for an EO event. Cancellation policies and
                  penalties on all EO Bangalore events are strictly enforced
                  with no exceptions. Travel Insurance, in many cases, may cover
                  unforeseen or uncontrollable situations that might affect your
                  ability to attend an event. It is the responsibility of the
                  member to arrange for travel, including, but not limited to,
                  all immigration/visa related costs and paperwork, in a timely
                  manner for attendance at EO events. If a member fails to
                  receive the appropriate approvals to enter a country in which
                  he or she has purchased a registration, the member shall be
                  subject to EO cancellation policies.
                </p>
                <p>
                  Cancellations caused by the impacts of COVID-19 or any other
                  illness or travel restriction are subject to provisions of
                  this policy. There are no exceptions for limitations caused by
                  COVID-19, including, but not limited to, governmental
                  limitations on travel, restrictions on gathering, personal or
                  family illness, mandatory quarantines, or failure to meet an
                  event safety protocol such as negative testing, masking or
                  vaccination.
                </p>
                <p>
                  EO Bangalore takes no responsibility for acquiring or
                  monitoring the status of a visa for any member. EO Bangalore
                  may support members in their application for visa and customs
                  approval. In such cases, the member seeking support must
                  provide the following:
                </p>

                <ul>
                  <li>
                    {" "}
                    <span className="bulter bg-yello"></span>{" "}
                    <span style={{ flex: "1 1 30px" }}>
                      {" "}
                      letters of invitation{" "}
                    </span>
                  </li>
                  <li>
                    <span className="bulter bg-purple"></span>{" "}
                    <span style={{ flex: "1 1 30px" }}>
                      {" "}
                      hotel confirmation numbers (where applicable)
                    </span>
                  </li>
                  <li>
                    <span className="bulter bg-green"></span>{" "}
                    <span style={{ flex: "1 1 30px" }}>
                      {" "}
                      connections to the Chapter members via the Chapter Admin
                    </span>
                  </li>
                </ul>
                <p>
                  EO Bangalore will not sponsor members, guarantee costs or
                  other monetary-related paperwork, or liaise with
                  ministry/immigration/embassies/consulates on behalf of any
                  member. If a member requires sponsorship for entry into a
                  country, the member assumes all risks associated with
                  registering for the event prior to getting approval and
                  finding sponsors; EO Bangalore cancellation policies apply.
                </p>
                <p>
                  All members travelling to India for RIE 2025 are advised to
                  apply for the visa well in advance (latest by September 1,
                  2024). In any case, if a member is unable to obtain an Indian
                  Visa till the last date of travel, EO Bangalore shall refund
                  the registration fee, provided the member is able to share
                  evidence of timely application for the visa as per the
                  suggested date (i.e. September 1, 2024) Final decision will be
                  at the discretion of EO Bangalore.
                </p>

                <p>
                  For any questions, please contact{" "}
                  <a href="mailto:rie@eobangalore.com" className="text-blue">
                    {" "}
                    rie@eobangalore.com{" "}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
