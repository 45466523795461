import { Helmet } from "react-helmet";

import { Img, Text, Heading, Button } from "../../components";
import EventSchedule from "../../components/EventSchedule";
import Header from "../../components/Header";

import KeynoteSession from "../../components/KeynoteSession";
import SpeakersProfile from "../../components/SpeakersProfile";
import React, { Suspense, useState, useRef, useEffect } from "react";
import Footer from "components/Footer/Footer";
import AboutSlide from "components/AboutSlider/AboutSlide";
import EventSlider from "components/EventSlider/EventSlider";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";

export default function Home1Page() {
  const videoRef1 = useRef(null); // Reference to the video element
  const videoRef2 = useRef(null); // Reference to the video element
  const videoRef3 = useRef(null); // Reference to the video element
  const [isPlaying, setIsPlaying] = useState({
    video1: true,
    video2: true,
    video3: true,
  }); // State to manage play/pause

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalComingIsOpen, setModalComingIsOpen] = React.useState(false);
  const isiPhone = /iPhone/.test(navigator.userAgent);

  const navigate = useNavigate();

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    setModalComingIsOpen(false);
  }

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      maxWidth: "450px",
      width: "calc(100% - 20px)",
      borderRadius: "10px",
    },
  };

  const handleRegsiter = () => {
    let url = "https://register.rie2025.com";
    window.open(url, "_blank", "noreferrer");
    // setModalComingIsOpen(true);
  };
  const handlePlayPause = (videoKey, videoRef) => {
    const video = videoRef.current;
    if (video) {
      if (video.paused) {
        video.play();
        setIsPlaying({ ...isPlaying, [videoKey]: true });
      } else {
        video.pause();
        setIsPlaying({ ...isPlaying, [videoKey]: false });
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>RIEMIX 2025 Event - Transformative Experiences Await You</title>
        <meta
          name="description"
          content="Join us at RIEMIX 2025 for three days of keynote sessions, socials, and MyEO experiences. Register now to be part of this transformative event and plan your trip for January 9-12, 2025."
        />
      </Helmet>

      {/* home page section */}
      <div className="w-full bg-gray-900">
        <div className="bg-[url(/public/images/top-circle1.png)] bg-full bg-no-repeat top-circle">
          <div className="bg-[url(/public/images/top-half.png)] bg-full bg-no-repeat half-circle">
            <div className="bg-[url(/public/images/top3.png)] bg-full bg-no-repeat top-arrow">
              <div className="bg-[url(/public/images/top4.png)] bg-full bg-no-repeat circle-bg">
                <div className="flex  flex-col items-center bg-[url(/public/images/top-main.png)] bg-full bg-no-repeat lg:h-auto md:h-auto mainTopBg">
                  {/* hero section */}
                  <div className="flex justify-center self-stretch bg-black-900_7f py-[30px] lg:py-8 md:py-5 sm:py-4 video-container vdo-home">
                    <div className="container-xs mb-4 sm:mb-0 flex justify-center px-14 lg:p-5 md:p-5 md:px-5 sm:px-4">
                      <div className="flex w-[50%] flex-col items-center justify-end  lg:w-full md:w-full">
                        <div className="">
                          <video
                            width="100%"
                            className="video-background"
                            ref={videoRef1}
                            autoPlay
                            playsInline
                            loop
                            muted
                          >
                            <source
                              src={`${process.env.PUBLIC_URL}/video/HOME-BANNER.mp4`}
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>

                          <div className="videoDescp content">
                            {isiPhone && (
                              <button
                                id="myBtn"
                                className="mt-[48px] lg:mt-[148px] md:mt-[78px]"
                                onClick={() =>
                                  handlePlayPause("video1", videoRef1)
                                }
                              >
                                {/* {isPlaying ? "Pause" : "Play"} */}
                                {/* <Img
                        src="images/img_frame_icon.svg"
                        alt="event image"
                        className="h-[234px] w-[100%] md:h-[100px]"
                      /> */}
                                <Img
                                  src={`${process.env.PUBLIC_URL}/images/play.png`}
                                  alt="play"
                                  className="h-[50px] w-auto md:h-[50px]"
                                />
                                {/* <img  src={`${process.env.PUBLIC_URL}/images/play.png`} /> */}
                              </button>
                            )}

                            <div className="hostedTitle">
                              <p>
                                South Asia R
                                <span style={{ color: "#EB3C71" }}>I</span>
                                <span style={{ color: "#28A98A" }}>E</span>
                              </p>
                              <p>
                                <span className="f-w-normal">hosted by</span> EO
                                Bangalore
                              </p>
                            </div>

                            <Heading
                              size="heading3xl"
                              as="h2"
                              className={`!text-[84.24px] md:!text-2xl lg:!text-5xl`}
                            >
                              January 9 - 12, 2025
                            </Heading>
                            <Button
                              color="pink_400"
                              size="md"
                              shape="square"
                              className="customize_btn mt-8 registerBtn min-w-[574px] lg:min-w-[400px] sm:min-w-[300px] font-extrabold lg:mx-0 lg:text-[40px] md:mx-0 md:text-[30px] sm:px-2 sm:text-[20px] 2xl:text-[40px] "
                              onClick={() => {
                                handleRegsiter();
                              }}
                            >
                              REGISTER NOW
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* about section */}
                  <div
                    className="mt-[40px] flex pt-set pt-[32px] md:pt-[30px] md:mt-[20px] flex-col self-stretch bg-[url(/public/images/bg-about.png)] bg-about-sec bg-no-repeat mainBg"
                    id="about"
                  >
                    <div className="container-xs flex flex-col gap-[30px] lg:p-5 md:p-5">
                      {/* <div className="banner_home">
                        <Img
                          src={`${process.env.PUBLIC_URL}/images/hola-banner-person.png`}
                          alt="play"
                          className="hola-banner-person"
                        />
                        <Img
                          src={`${process.env.PUBLIC_URL}/images/hola-banner.png`}
                          alt="play"
                          className=" w-full h-auto"
                        />
                      </div> */}

                      <div className="flex flex-col items-start gap-[54px] md:gap-[54px] sm:gap-[40px">
                        {/* <Heading
                          as="h2"
                          className="md:text-[34px] sm:text-[28px]"
                        >
                          ABOUT RIE
                        </Heading> */}
                        <div className="ml-2 mt-[50px] mt-custom self-stretch lg:ml-0 md:ml-0 sm:mt-[0px]">
                          <div className="flex items-center md:flex-col bg-black-900_7f">
                            <div className="w-[50%] object-cover md:w-full  ">
                              <div className="video-container aboutVideo">
                                <video
                                  width="100%"
                                  className="video-background"
                                  ref={videoRef2}
                                  autoPlay
                                  playsInline
                                  loop
                                  muted
                                >
                                  <source
                                    src={`${process.env.PUBLIC_URL}/video/about-rie.mp4`}
                                    type="video/mp4"
                                  />
                                  Your browser does not support the video tag.
                                </video>

                                <div className="videoDescp content">
                                  {isiPhone && (
                                    <button
                                      id="myBtn2"
                                      onClick={() =>
                                        handlePlayPause("video2", videoRef2)
                                      }
                                    >
                                      <Img
                                        src={`${process.env.PUBLIC_URL}/images/play.png`}
                                        alt="play"
                                        className="h-[50px] w-auto md:h-[50px]"
                                      />
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                            {/* <Img
                      src="images/img_video.png"
                      alt="video image"
                      className="h-[466px] "
                    /> */}
                            <div className="flex-1 px-[34px] w-[50%] md:w-full  lg:py-8 md:self-stretch md:py-5 sm:p-4">
                              <h3 className="aboutSubheading">
                                Curated for Game Changers, Trailblazers and
                                Visionaries
                              </h3>
                              <Heading
                                size="headingmd"
                                as="h3"
                                className="mt-2 leading-[50px] md:text-2xl sm:text-[18px] font-normal"
                              >
                                <>
                                  Get ready for three thrilling days packed with
                                  great content, people and experiences. RIEMIX
                                  2025 promises to be a transformative event
                                  with:{" "}
                                </>
                              </Heading>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col items-center gap-3.5">
                        <div className="flex gap-10 self-stretch md:flex-col flex-col aboutSlider md:mb-10">
                          <AboutSlide />
                        </div>
                        <Heading
                          size="heading2xl"
                          as="h4"
                          className="!font-bold !text-pink-400 lg:text-5xl sm:text-4xl  mt-[30px]"
                        >
                          <span className="text-amber-300">Turn Up .</span>
                          <span className="text-white-a700">&nbsp;</span>
                          <span className="text-pink-400">Tune In</span>
                        </Heading>
                        <Button
                          color="teal_400"
                          hoverColor="bg-pink-400"
                          shape="square"
                          className="lg:min-w-[412px] sm:min-w-[300px] registerBtn readMoreGreen  mt-[40px] font-extrabold lg:text-[51px] md:text-[40px] sm:px-4 sm:text-[20px]"
                          onClick={() => {
                            // openModal();
                            navigate("/about-rie");
                          }}
                        >
                          READ MORE
                        </Button>
                      </div>
                    </div>
                  </div>
                  {/* schedule section */}
                  <div className="w-full bg-[url(/public/images/dot-bigger.png)] bg-bigger-dot bg-no-repeat">
                    <div className="w-full bg-[url(/public/images/line-red-green.png)] bg-line-red-green bg-no-repeat">
                      <div className="w-full bg-[url(/public/images/arrow-green.png)] bg-arrow-green bg-no-repeat">
                        <div className="w-full bg-[url(/public/images/arrow-yellow.png)] bg-arrow-yellow bg-no-repeat">
                          <div className="w-full bg-[url(/public/images/dot1.png)] bg-dot bg-dot-one bg-no-repeat">
                            <div className="w-full bg-[url(/public/images/dot2.png)] bg-dot bg-dot-two bg-no-repeat">
                              <div className="w-full bg-[url(/public/images/dot3.png)] bg-dot bg-dot-three bg-no-repeat">
                                <div className="w-full bg-[url(/public/images/dot4.png)] bg-dot bg-dot-four bg-no-repeat">
                                  <div className="w-full bg-[url(/public/images/dot5.png)] bg-dot bg-dot-five bg-no-repeat">
                                    <div className="w-full bg-[url(/public/images/dot6.png)] bg-dot bg-dot-six bg-no-repeat">
                                      <section className="w-full bg-schedule-sec bg-no-repeat mainBg">
                                        <div
                                          className="mt-[72px] pt-[0px] sm:pt-[0px] sm:mt-[0px] flex flex-col self-stretch"
                                          id="schedule"
                                        >
                                          {/* speakers partners section */}
                                          <div className="container-xs flex flex-col gap-[82px] xl:gap-[82px] lg:p-5 md:gap-[61px] md:p-5 sm:gap-[41px]">
                                            <div className="flex flex-col items-center gap-[16px]">
                                              <Heading
                                                as="h2"
                                                className="self-start md:text-[34px] sm:text-[24px] mb-[30px] font-averta text-green"
                                              >
                                                SCHEDULE AT A GLANCE
                                              </Heading>
                                              <div className="flex gap-10 self-stretch flex-col md:flex-col w-full eventSlider">
                                                <EventSlider />
                                              </div>
                                              <p className="w-full block text-left md:text-left text-white-a700  font-averta relative mt-[5px]font-extrabold font-tentative">
                                                *Tentative schedule. Subject to
                                                changes
                                              </p>
                                              <Button
                                                color="amber_300"
                                                shape="square"
                                                className="min-w-[412px] registerBtn md:min-w-[312px] md:mt-[40px] font-extrabold readmoreOrange lg:text-[51px] md:text-[40px]  sm:px-4 sm:text-[20px]"
                                                onClick={() => {
                                                  // openModal();
                                                  navigate("/event");
                                                }}
                                              >
                                                READ MORE
                                              </Button>
                                            </div>
                                          </div>
                                        </div>
                                        {/* Speakers section */}
                                        <div className="mt-[50px] sm:mt-[0px] flex flex-col self-stretch">
                                          <div className="container-xs flex flex-col gap-[82px] lg:gap-[82px] lg:p-5 md:gap-[61px] md:p-5 sm:gap-[41px]">
                                            <div className="flex flex-col gap-20">
                                              <SpeakersProfile titleColor="#EB3D71" />
                                            </div>
                                          </div>
                                        </div>
                                      </section>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-full bg-[url(/public/images/f-top-circle.png)] f-top-circle bg-no-repeat">
                    <div className="w-full bg-[url(/public/images/f-left-arrow.png)] f-left-arrow bg-no-repeat">
                      <section className="w-full bg-footer-sec bg-no-repeat mainBg">
                        {/* Patners section */}
                        <div className="mt-[82px] sm:mt-[0px] flex flex-col self-stretch">
                          <div className="container-xs flex flex-col gap-[82px] lg:gap-[82px] lg:p-5 md:gap-[61px] md:p-5 sm:gap-[41px]">
                            <SpeakersProfile
                              titleText="PARTNERS"
                              titleColor="#2AA98B"
                            />
                          </div>
                        </div>
                        {/* plan your trip section */}
                        <div
                          className="container-xs mt-[50px] sm:mt-[0px] mb-[186px] md:mb-[20px] lg:p-5 md:p-5 pt-[50px]"
                          id="planTrip"
                        >
                          <div className="flex flex-col items-center gap-[52px] sm:gap-[26px]">
                            <div className="flex text-orange  flex-col items-start gap-[46px] self-stretch">
                              <Heading
                                as="h2"
                                className="md:text-[34px] sm:text-[28px]  text-orange "
                              >
                                PLAN YOUR TRIP
                              </Heading>
                              <div className="h-[940px] self-stretch bg-[url(/public/images/img_video_940x1672.png)] bg-cover bg-no-repeat lg:h-auto md:h-auto">
                                <div className="video-container aboutVideo">
                                  <video
                                    width="100%"
                                    className="video-background"
                                    ref={videoRef3}
                                    autoPlay
                                    playsInline
                                    loop
                                    muted
                                  >
                                    <source
                                      src={`${process.env.PUBLIC_URL}/video/plan-you-trip.mp4`}
                                      type="video/mp4"
                                    />
                                    <source
                                      src={`${process.env.PUBLIC_URL}/video/plan-you-trip.webm`}
                                      type="video/webm"
                                    />
                                    Your browser does not support the video tag.
                                  </video>

                                  <div className="videoDescp content">
                                    {isiPhone && (
                                      <button
                                        id="myBtn3"
                                        onClick={() =>
                                          handlePlayPause("video3", videoRef3)
                                        }
                                      >
                                        <Img
                                          src={`${process.env.PUBLIC_URL}/images/play.png`}
                                          alt="play"
                                          className="h-[50px] w-auto md:h-[50px]"
                                        />
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <Button
                              color="indigo_500"
                              shape="square"
                              className="min-w-[412px] registerBtn md:min-w-[312px] font-extrabold lg:text-[51px] md:text-[40px] sm:px-4 sm:text-[20px]"
                              onClick={() => {
                                // openModal();
                                navigate("/plan-your-trip");
                              }}
                            >
                              READ MORE
                            </Button>
                          </div>
                        </div>
                        {/* footer section */}
                        <Footer />
                      </section>
                    </div>
                  </div>

                  <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                  >
                    <button onClick={closeModal} className="btnClose">
                      <Img
                        src={`${process.env.PUBLIC_URL}/images/close.svg`}
                        alt="image"
                        width="16px"
                        height="16px"
                      />
                    </button>
                    <div className="py-20 px-10">
                      <Img
                        src={`${process.env.PUBLIC_URL}/images/popup-logo.png`}
                        alt="image"
                        className="popupLogo"
                      />
                    </div>
                  </Modal>

                  <Modal
                    isOpen={modalComingIsOpen}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                  >
                    <button onClick={closeModal} className="btnClose">
                      <Img
                        src={`${process.env.PUBLIC_URL}/images/close.svg`}
                        alt="image"
                        width="16px"
                        height="16px"
                      />
                    </button>
                    <div className="py-20 px-10">
                      <Img
                        src={`${process.env.PUBLIC_URL}/images/popup-logo.png`}
                        alt="image"
                        className="popupLogo"
                      />
                      <p className="text-[24px] text-center w-full mt-5">
                        Opening at 18:30
                      </p>
                    </div>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
