import { Heading, Img } from "./..";
import React from "react";

export default function SpeakersProfile({ titleText = "SPEAKERS", titleColor="#2AA98B", statusText, ...props }) {
  return (
    <div {...props} className={`${props.className} flex flex-col items-start gap-[54px] sm:gap-[27px] flex-1`}>
      <Heading as="h1" className="md:text-[34px] sm:text-[28px] " style={{color:titleColor}}>
        {titleText}
      </Heading>
      <div className="flex w-[34%] flex-col items-center self-center sm-w-100">
        <Img
          src={`${process.env.PUBLIC_URL}/images/img_group_29.png`}
          alt="logo image"
          className="h-[132px] w-full object-contain lg:h-auto md:h-auto"
        />
        <Heading size="heading2xl" as="h1" className="relative mt-[-44px] sm:mt-[-24px] !text-teal-400_01 lg:text-5xl sm:text-3xl progress_heading">
          <span className="text-white-a700 in_progress">IN PROGRESS</span>
          <span className="text-pink-a200 animate-pulse">.</span>
          <span className="text-amber-300_01 animate-pulse delay-300">.</span>
          <span className="text-teal-400_01 animate-pulse delay-700">.</span>
        </Heading>
      </div>
    </div>
  );
}
