import React, { useState } from "react";

const HoverImage = ({ defaultImage, hoverImage, altText, className,onClick }) => {
  const [imageSrc, setImageSrc] = useState(defaultImage);

  return (
    <img
      src={imageSrc}
      alt={altText}
      onMouseEnter={() => setImageSrc(hoverImage)}
      onMouseLeave={() => setImageSrc(defaultImage)}
      className={className}
      onClick={onClick}
    />
  );
};

export default HoverImage;
