import React from "react";
import { useRoutes } from "react-router-dom";
import Home from "pages/Home";
import NotFound from "pages/NotFound";
import Home1 from "pages/Home1";
import AboutUs from "pages/about/index";
import Faq from "pages/Faq";
import PlanTrip from "pages/trip-plan";
import Event from "pages/event";
import CancellationPolicy from "pages/cancellationPolicy";
import Sustainability from "pages/Sustainability";

const ProjectRoutes = () => {
  let element = useRoutes([
    { path: "/", element: <Home1 /> },
    { path: "*", element: <NotFound /> },
    { path: "/about-rie", element: <AboutUs /> },
    { path: "/event", element: <Event /> },
    { path: "/plan-your-trip", element: <PlanTrip /> },
    { path: "/faq", element: <Faq /> },
    { path: "/cancellation-policy", element: <CancellationPolicy /> },
    { path: "/sustainability", element: <Sustainability /> },
    {
      path: "home1",
      element: <Home1 />,
      // eslint-disable-next-line no-dupe-keys
      element: <AboutUs />,
      element: <Faq />,
      element: <Event />,
      element: <CancellationPolicy />,
    },
  ]);

  return element;
};

export default ProjectRoutes;
