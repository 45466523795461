import { Heading } from "./..";
import React from "react";

export default function KeynoteSession({
  keynoteText = "Keynote Sessions",
  desc="",
  ...props
}) {
  return (
    <>
      <div
        {...props}
        className={`${props.className} flex justify-center about-card items-center w-[32%] md:w-full  lg:py-8 md:p-5 sm:p-4`}
      >
        <div className="flip-card-inner">
          <div className="flip-card-front">
            <Heading
              as="h1"
              className={`w-[52%] lg:w-[100%] text-center leading-[60px] ${props.headingClassName}`}
            >
              {keynoteText}
            </Heading>
          </div>
          <div className="flip-card-back">
          {desc}
          </div>
        </div>
      </div>
    </>
  );
}
