export const eventPopupFixture = {
  img1: {
    heading: "RIEMIX Open Golf Tournament",
    eImg: `${process.env.PUBLIC_URL}/images/event/golf-hover.png`,
    eDetail: [
      {
        title: "Date:",
        desc: "9th January 2025",
      },
      {
        title: "Tour time:",
        desc: "10 AM - 4 PM",
      },
      {
        title: "Venue:",
        desc: "Prestige Golfshire Clubhouse",
      },
      {
        title: "Price:",
        desc: "INR 9900 + GST",
      },
    ],
    highlights: [
      "Tee off with your EO buddies in a 4-Ball format game of golf.",
      "Play at the beautiful Prestige Golfshire course, rated one of India's best golf courses.",
      "Play for the prestigious RIEMIX Trophy",
    ],
    Overview: [
      "Tee off at the beautiful Prestige Golfshire, where the stunning greens overlooking the verdant Nandi Hills and the glorious Bangalore weather provide the perfect setting for an unforgettable day.",
      "Join your fellow EO members on one of the country’s most prestigious golf courses to forge new friendships, share laughs, and enjoy a leisurely yet spirited game of golf together followed by fellowship at the 19th hole.",
    ],
    others: [
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-9.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-9.svg`,
        heading: "COMMUNITY",
      },
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-1.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-1.svg`,
        heading: "EMOTIONS",
      },
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-8.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-8.svg`,
        heading: "CONNECTION",
      },
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-10.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-10.svg`,
        heading: `OORU `,
      },
    ],
  },
  img2: {
    heading: "One Night In Tokyo",
    eImg: `${process.env.PUBLIC_URL}/images/event/tokyo_img.png`,
    eDetail: [
      {
        title: "Date:",
        desc: "9th January 2025",
      },
      {
        title: "Tour time:",
        desc: "6 PM - 10 PM",
      },
      {
        title: "Venue:",
        desc: "Naru Noodle Bar",
      },
      {
        title: "Price:",
        desc: "INR 10,000 + GST",
      },
    ],
    highlights: [
      "6-course meal featuring ramen, unagi, paired with sake by acclaimed Chef Kavan Kutappa, rated amongst the top 10 chefs in India.",
      "Exclusive access to the hardest-to-book restaurant in India! Rated the #1 restaurant in Bangalore by Conde Nast Traveler.",
      "Dive into “Wine in Progress”, Bangalore’s hippest wine destination for a quick journey to the best vineyards before the Japanese degustation begins.",
      "The meal will cater to both vegetarian and non-vegetarian dietary preferences.",
    ],
    Overview: [
      "Unveil an evening of sophisticated elegance with a private dinner at Bangalore’s famous Naru Noodle Bar. We’re opening the doors to an exclusive night of flavours, stories, and connections in one of the city’s most coveted spots. ",
      "Curated exclusively for an intimate group of 24, this bespoke dining experience features a gourmet menu blending the finest of Japanese contemporary cuisine, paired perfectly with premium sake and wine. This promises an unforgettable night of culinary excellence and distinguished company.",
    ],
    others: [
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-9.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-9.svg`,
        heading: "COMMUNITY",
      },
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-1.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-1.svg`,
        heading: "EMOTIONS",
      },
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-8.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-8.svg`,
        heading: "CONNECTION",
      },
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-10.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-10.svg`,
        heading: "OORU",
      },
    ],
  },
  img3: {
    heading: "The Modern Harvest Supper",
    eImg: `${process.env.PUBLIC_URL}/images/event/Harvest-hover.png`,
    eDetail: [
      {
        title: "Date:",
        desc: "9th January 2025",
      },
      {
        title: "Tour time:",
        desc: "6:30 PM - 10:30 PM",
      },
      {
        title: "Venue:",
        desc: "Farmlore",
      },
      {
        title: "Price:",
        desc: "Rs. 12000 + GST",
      },
    ],
    highlights: [
      " 11-course meal paired with exquisite wines, each course representing ‘The Spirit of Entrepreneurship'.",
      " By acclaimed Chef Johnson Ebenezer, named #2 in India for 2024 by Culinary Culture.",
      " The meal will cater to both vegetarian and non-vegetarian dietary preferences.",
    ],
    Overview: [
      "Step into the world of Farmlore, a breathtaking 37-acre farm on the outskirts of Bangalore dedicated to preserving and reimagining the ‘Locavore’ traditions of the region. Helmed by Chef Johnson Ebenezer, rated one of India’s top chefs, Farmlore offers more than just a meal; it’s an immersive journey into heritage, sustainability, and innovation.",
      "Join us for a one-of-a-kind, interactive dining experience encapsulating ‘The Spirit of Entrepreneurship’ where each course is a story, each ingredient a piece of history, thoughtfully curated to offer you a taste of sustainable gastronomy.",
    ],
    others: [
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-9.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-9.svg`,
        heading: "COMMUNITY",
      },
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-7.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-7.svg`,
        heading: "INNOVATION",
      },
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-8.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-8.svg`,
        heading: "CONNECTION",
      },
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-10.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-10.svg`,
        heading: "OORU",
      },
    ],
  },
  img4: {
    heading: "Conscious Connected Breathwork",
    eImg: `${process.env.PUBLIC_URL}/images/event/conscious-hover.png`,
    eDetail: [
      {
        title: "Date:",
        desc: "9th January 2025",
      },
      {
        title: "Tour time:",
        desc: "5 PM - 7 PM",
      },
      {
        title: "Venue:",
        desc: "JW Marriott Golfshire",
      },
      {
        title: "Price:",
        desc: "INR 2500 + GST",
      },
    ],
    highlights: [
      "Explore the power and depth of the breath through the Conscious Connected Breathwork technique.",
      "Engage in mindful movement, yoga, gentle meditation, chanting and some fun games.",
      "Led by Arjun, a certified yoga teacher who left a two-decade career in corporate law to explore the breath-mind-body relationship through conscious breathing practices and mindful movement.",
    ],
    Overview: [
      "“Breath is the bridge which connects life to consciousness, which unites your body to your thoughts.” – Thich Nhat Hanh",
      "Each day, we take between 22,000 to 25,000 breaths, often without a second thought. But when we bring awareness to this vital rhythm, we allow the mind to settle, like ripples fading on a lake, revealing a clear view into our inner depths.Join us for a transformative session where you learn techniques to enhance your well-being. Discover the profound benefits of mindful breathing, from detoxifying the body and reducing stress to boosting immune health and improving physical performance. Let this be your first step towards a more balanced, conscious self.",
    ],
    others: [
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-5.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-5.svg`,
        heading: "INGENUITY",
      },
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-1.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-1.svg`,
        heading: "EMOTIONS",
      },
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-4.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-4.svg`,
        heading: "TRANSFORMATION",
      },
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-11.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-11.svg`,
        heading: "OPTIMISM",
      },
    ],
  },
  img5: {
    heading: "The Business Of Sport",
    eImg: `${process.env.PUBLIC_URL}/images/event/Sport-hover.png`,
    eDetail: [
      {
        title: "Date:",
        desc: "9th January 2025",
      },
      {
        title: "Tour time:",
        desc: "3 PM - 6 PM",
      },
      {
        title: "Venue:",
        desc: "Padukone-Dravid Centre for Sports Excellence",
      },
      {
        title: "Price:",
        desc: "INR 2000 + GST",
      },
    ],
    highlights: [
      "Get exclusive behind-the-scenes access to the sports facilities that proudly sent multiple athletes to the Paris Olympics 2024. ",
      "Interact with the founders, and get exclusive insights into running a successful sports facility, and the business of sport.",
      "Visit the sports museum and residences and watch pro athletes in action.",
      "Enjoy canapés at Amiel Gourmet Café",
      "Championed by EO Bangalore’s Rishi Sridhar,  former India #1 and International tennis player.",
    ],
    Overview: [
      "Spanning 16 acres, the Padukone–Dravid Centre for Sports Excellence (CSE) is the first-of-its-kind, fully integrated centre offering world-class infrastructure across nine sports: Badminton, Cricket, Football, Tennis, Swimming, Shooting, Squash, Basketball and Volleyball. Designed to serve high-performance athletes, experienced coaches, and recreational enthusiasts alike, CSE provides a seamless experience for all levels of sport.",
      "Join us for an exclusive, specially curated visit to this premier sporting hub named in honor of its legendary founders. Discover what makes this centre an elite destination for training, competition, and recreation in an inspiring setting.",
    ],
    others: [
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-7.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-7.svg`,
        heading: "INNOVATION",
      },
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-6.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-6.svg`,
        heading: "LEARNING",
      },
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-2.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-2.svg`,
        heading: "ENTREPRENEURSHIP",
      },
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-5.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-5.svg`,
        heading: "INGENUITY",
      },
    ],
  },
  img6: {
    heading: "A Walk In The Park - A BTS Visit To BIAL",
    eImg: `${process.env.PUBLIC_URL}/images/event/bial-hover.png`,
    eDetail: [
      {
        title: "Date:",
        desc: "9th January 2025",
      },
      {
        title: "Tour time:",
        desc: "3 PM - 6 PM",
      },
      {
        title: "Venue:",
        desc: "Kempegowda International Airport",
      },
      {
        title: "Price:",
        desc: "INR 4000 + GST",
      },
    ],
    highlights: [
      "Get behind-the-scenes access to Terminal 2 of Bangalore’s Kempegowda International Airport, a remarkable space combining sustainability and technology.",
      "Learn about the complex logistics involved in running an airport.",
      "Interact with CEO and Managing Director of Bangalore International Airport Limited (BIAL), Hari Marar.",
      "Learn about how advanced technologies such as internet of things (IoT), Intelligent Building Systems, solar shading etc have made T2 the largest terminal in the world to achieve a pre-certified LEED Platinum rating.",
    ],
    Overview: [
      "Step into a world where 900 kilometers of bamboo line the architecture and 1,50,000 live plants adorn the ceilings. Wander through a biodiversity haven, home to hundreds of plant species, a living homage to India's ecological richness. This is not ‘A Walk in the Park’, this is Terminal 2 of Bangalore’s Kempegowda International Airport. ",
      "More than just a green airport, it is a tribute to Bangalore, the Garden City. The terminal combines natural beauty with cutting-edge design to create an unparalleled passenger experience where every detail reflects both sustainability and technology.",
      " Hear directly from the visionary CEO and Managing Director of Bangalore International Airport Limited (BIAL), Hari Marar, about the many firsts that T2 has achieved, the success of the public–private partnership model, and their plans for the future.",
    ],
    others: [
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-7.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-7.svg`,
        heading: "INNOVATION",
      },
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-6.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-6.svg`,
        heading: "LEARNING",
      },
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-2.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-2.svg`,
        heading: "ENTREPRENEURSHIP",
      },
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-10.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-10.svg`,
        heading: "OORU",
      },
    ],
  },
  img7: {
    heading: "By 2 Coffee - A Brewing Workshop",
    eImg: `${process.env.PUBLIC_URL}/images/event/Coffee-hover.png`,
    eDetail: [
      {
        title: "Date:",
        desc: "9th January 2025",
      },
      {
        title: "Tour time:",
        desc: "3 PM to 6 PM",
      },
      {
        title: "Venue:",
        desc: "Devagiri Farms, Devenahalli",
      },
      {
        title: "Price:",
        desc: "INR 5,000 + GST",
      },
    ],
    highlights: [
      "Experience speciality coffee tasting in a rustic farm setting.",
      "Learn the business of coffee growing with a fifth-generation coffee planter.",
      "Enjoy a masterclass on the history of coffee in India and a hands-on session on manual brewing techniques from a professional barista.",
    ],
    Overview: [
      "Discover the evolution of coffee in a serene farm setting in Bengaluru, the cradle of the country’s coffee culture. Join us at Devagiri Farms for a captivating coffee brewing workshop that promises an immersive, sensory experience for all coffee enthusiasts.",
      "Begin with a guided walk through the farm exploring the principles of organic farming and learning how coffee plants are nurtured from soil to bean. Dive into a hands-on brewing session and master techniques like the French Press and Pour Over using freshly roasted beans from the farm. End with a guided coffee tasting experience and savour the complex flavours and aromas that make each cup unique. Perfect for those looking to enrich their brewing skills and deepen their connection to coffee.",
    ],
    others: [
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-7.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-7.svg`,
        heading: "INNOVATION",
      },
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-6.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-6.svg`,
        heading: "LEARNING",
      },
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-2.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-2.svg`,
        heading: "ENTREPRENEURSHIP",
      },
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-10.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-10.svg`,
        heading: "OORU",
      },
    ],
  },
  img8: {
    heading: "Death By Dosa",
    eImg: `${process.env.PUBLIC_URL}/images/event/dosa-new-hover.png`,
    eDetail: [
      {
        title: "Date:",
        desc: "9th January 2025",
      },
      {
        title: "Tour time:",
        desc: "2:30 PM to 6 PM",
      },
      {
        title: "Venue:",
        desc: "Kadu Malleshwara Temple, Malleshwaram",
      },
      {
        title: "Price:",
        desc: "INR 3,000 + GST",
      },
    ],
    highlights: [
      "Enjoy fluffy idlis, decadent dosas and more at the famous Veena Stores, Eat Raja and CTR in Malleshwaram.",
      "Explore the history of the neighbourhood and immerse yourself in the graphic street art with an expert guide.",
      "Visit the 17th century Kadu Malleshwara temple and discover the heritage architecture of the area.",
    ],
    Overview: [
      "Embark on a culinary tour through Malleshwaram, one of Bangalore's oldest and most vibrant neighbourhoods. Stroll through its bustling streets, discover iconic eateries and savour local delicacies like crispy dosas, idlis and filter coffee. ",
      "Alongside these culinary delights, immerse yourself in the rich heritage of Malleshwaram, exploring traditional homes, historic temples, and bustling marketplaces that tell the stories of this cherished locale. For food lovers and history enthusiasts alike, this experience offers a feast for the senses, blending the taste of Bangalore’s beloved dishes with the soul of its enduring culture.",
    ],
    others: [
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-9.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-9.svg`,
        heading: "COMMUNITY",
      },
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-1.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-1.svg`,
        heading: "EMOTIONS",
      },
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-8.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-8.svg`,
        heading: "CONNECTIONS",
      },
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-10.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-10.svg`,
        heading: "OORU",
      },
    ],
  },
  img9: {
    heading: "Tipsy Tour",
    eImg: `${process.env.PUBLIC_URL}/images/event/Tipsy-hover.png`,
    eDetail: [
      {
        title: "Date:",
        desc: "9th January 2025",
      },
      {
        title: "Tour time:",
        desc: "5 PM to 9:30 PM",
      },
      {
        title: "Venue:",
        desc: "Barton Center, MG Road",
      },
      {
        title: "Price:",
        desc: "INR 5500 + GST",
      },
    ],
    highlights: [
      "Understand the history of beer culture in Bangalore and learn what makes Bangalore the pub capital of India.",
      "Explore Bangalore’s local gems - from the colonial era pubs to modern watering holes including 13th Floor, Standing Bar, Koshy’s and Bar Spirit Forward.",
    ],
    Overview: [
      "Experience the pub capital of India in true EO style as we pub hop through the lively MG Road area to some of Bangalore’s most iconic pubs and hidden gems, each offering its own unique character and charm. ",
      "From historic watering holes with legendary stories to new and trendy bars, get a taste of the city’s vibrant nightlife in true Bangalorean style. Savour signature drinks, soak up the buzzing atmosphere and swap stories with fellow explorers as we make our way from one stop to the next. Perfect for those looking to dive into the local culture, this experience is a lively mix of delicious brews and good vibes.",
    ],
    others: [
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-9.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-9.svg`,
        heading: "COMMUNITY",
      },
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-1.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-1.svg`,
        heading: "EMOTIONS",
      },
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-8.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-8.svg`,
        heading: "CONNECTIONS",
      },
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-10.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-10.svg`,
        heading: "OORU",
      },
    ],
  },
  img10: {
    heading: "The Mysore Maharajah",
    eImg: `${process.env.PUBLIC_URL}/images/event/Maharajah-hover.png`,
    eDetail: [
      {
        title: "Date:",
        desc: "9th January 2025",
      },
      {
        title: "Tour time:",
        desc: "8 AM to 9 PM",
      },

      {
        title: "Price:",
        desc: "INR 9000 + GST",
      },
    ],
    highlights: [
      "An entire day’s trip from Bangalore to Mysore covering history, culture, art and multiple experiences with local food.",
      "Discover the beauty of the Mysore Palace and learn about the history of the Wadiyar empire.",
      "Try the original Mysore Pak from the family that invented it!",
      "Witness traditional artisans exercise their craft during the visit to the century old Mysore market.",
    ],
    Overview: [
      "Experience the best of Mysore on a day trip that immerses you in the captivating history of Tipu Sultan and the legacy of the Wadiyars. Embark on a whirlwind tour of the historic city, exploring its grand palaces and iconic landmarks, all while savouring the rich flavours of the local cuisine. ",
      "Explore the century old Devraja market brimming with traditional products and watch the artisans at work. Indulge in the culinary delights of the region, including the famous dosa and the delectable Mysore Paak. Experience an enchanting combination of history, culture and gastronomy, offering you a comprehensive glimpse into Mysore's rich heritage—all in a day!",
    ],
    others: [
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-6.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-6.svg`,
        heading: "LEARNING",
      },
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-3.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-3.svg`,
        heading: "INTERCONNECTEDNESS",
      },
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-8.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-8.svg`,
        heading: "CONNECTIONS",
      },
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-4.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-4.svg`,
        heading: "TRANSFORMATION",
      },
    ],
  },
  img11: {
    heading: "Tipu’s Trail - A Walk Through History",
    eImg: `${process.env.PUBLIC_URL}/images/event/Tipus-hover.png`,
    eDetail: [
      {
        title: "Date:",
        desc: "9th January 2025",
      },
      {
        title: "Tour time:",
        desc: "10.30 AM to 2.30 PM",
      },
      {
        title: "Venue:",
        desc: "Nandi Hills",
      },
      {
        title: "Price:",
        desc: "Rs.5500 + GST",
      },
    ],
    highlights: [
      "Trek to the 8th century Bhoga Nandeeshwara",
      "Temple and the Sultanpet British Cemetery. ",
      "Visit local potters and learn about their craft.",
      "Enjoy a delectable meal with a stunning view of the hills",
    ],
    Overview: [
      "Join us on a captivating trail that begins with a visit to the 9th-century Bhoga Nandeeshwara Temple and enjoy the serene beauty of the temple's stepwell. Trek to the intriguing 19th-century Sultanpet British Cemetery and discover the history and evolution of the region. Delve into the history of the Battle of Nandidurg, where the British forces triumphed over Tipu Sultan, reshaping the future of this area. End the trail with a visit to the local potter community and try your hand at pottery in a traditional setting.",
      "Conclude this enriching experience with a scrumptious meal in a stunning villa overlooking the ethereal Nandi Hills. This experience offers a perfect blend of history, culture, and culinary delight, creating lasting memories of this enchanting region.",
    ],
    others: [
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-9.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-9.svg`,
        heading: "COMMUNITY",
      },
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-6.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-6.svg`,
        heading: "LEARNING",
      },
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-8.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-8.svg`,
        heading: "CONNECTIONS",
      },
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-10.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-10.svg`,
        heading: "OORU",
      },
    ],
  },
  img12: {
    heading: "Masterclass With Magnolia Bakery",
    eImg: `${process.env.PUBLIC_URL}/images/event/Masterclass-hover.png`,
    eDetail: [
      {
        title: "Date:",
        desc: "9th January 2025",
      },
      {
        title: "Tour time:",
        desc: "3.30 PM to 5 PM | 5.30 PM to 7 PM",
      },
      {
        title: "Venue:",
        desc: "JW Marriott Golfshire",
      },
      {
        title: "Price:",
        desc: "INR 4000 + GST",
      },
    ],
    highlights: [
      "Participate in a fun baking masterclass from the world-famous Magnolia Bakery.",
      "Learn the art of cupcake making and the trademarked buttercream swirl from the executive chef of Magnolia Bakery.",
      "Conclude the session with a delectable dessert tasting bar featuring all of Magnolia’s bestsellers!",
    ],
    Overview: [
      "Discover the secret to baking irresistibly delicious cupcakes in a hands-on masterclass by the renowned Magnolia Bakery. Founded in New York City's West Village in 1996, Magnolia Bakery quickly rose to global fame, even earning a spotlight in the TV show, Sex and the City.",
      "In this exclusive session, learn how to make Magnolia’s classic vanilla cupcakes directly from their executive chef. Master their signature buttercream swirl, the perfect finishing touch to your freshly baked cupcakes! Whether you are a novice or a seasoned baker, sign up to pick up some insider tips, professional techniques, and recipes to take your baking to the next level.",
    ],
    others: [
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-7.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-7.svg`,
        heading: "INNOVATION",
      },
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-6.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-6.svg`,
        heading: "LEARNING",
      },
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-5.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-5.svg`,
        heading: "INGENUITY",
      },
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-8.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-8.svg`,
        heading: "CONNECTION",
      },
    ],
  },
  img13: {
    heading: "Partner Yoga Workshop",
    eImg: `${process.env.PUBLIC_URL}/images/event/Partner-Yoga-hover.png`,
    eDetail: [
      {
        title: "Date:",
        desc: "11th January 2025",
      },
      {
        title: "Tour time:",
        desc: "7 AM - 8 AM",
      },
      {
        title: "Venue:",
        desc: "JW Marriott Golfshire",
      },
      {
        title: "Price:",
        desc: "INR 2000 + GST",
      },
    ],
    highlights: [
      "Feel physically rewarded and experience yoga in a fresh, collaborative way.",
      "Experience deeper relaxation and unlock yoga poses that often seem unattainable.",
      "Led by Thanvi, an internationally certified yoga professional, who specializes in Hatha, Vinyasa and aerial yoga.",
    ],
    Overview: [
      "Partner yoga allows two people to support each other in poses, thus enhancing posture, balance, and fostering trust and communication. Join us for a transformative yoga experience designed to deepen both asana alignment and human connection.",
      "Learn steps to work with a partner to make challenging asanas more accessible and encourage a deeper flow that is hard to achieve alone. Come experience a harmonious connection through shared movements and breathwork, and extend beyond your individual practice in a truly collaborative environment. ",
    ],
    others: [
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-8.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-8.svg`,
        heading: "CONNECTION",
      },
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-5.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-5.svg`,
        heading: "INGENUITY",
      },
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-1.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-1.svg`,
        heading: "EMOTIONS",
      },
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-4.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-4.svg`,
        heading: "TRANSFORMATION",
      },
    ],
  },
  img14: {
    heading: "Animal Flow Workshop",
    eImg: `${process.env.PUBLIC_URL}/images/event/Animal-Flow-hover.png`,
    eDetail: [
      {
        title: "Date:",
        desc: "10th January 2025",
      },
      {
        title: "Tour time:",
        desc: "7 AM - 8.30 AM",
      },
      {
        title: "Venue:",
        desc: "JW Marriott Golfshire",
      },
      {
        title: "Price:",
        desc: "INR 2000 + GST",
      },
    ],
    highlights: [
      "Feel physically rewarded with animal movements designed to improve strength, flexibility and mobility for all levels of fitness enthusiasts.",
      "Includes warm-up and preparation, breakdown of fundamental movements and flow combinations.",
      "Led by Akshay Sharma, an Animal Flow Level 1 and 2 coach, regional leader for AF in Bangalore, and a holistic movement coach. ",
    ],
    Overview: [
      "Join us for a one-of-a-kind animal flow movement workshop that is both challenging and fun! Discover the art of building functional strength and flexibility by moving like animals. Learn foundational moves like the Ape, Beast, Crab, and Scorpion and how to transition between movements with grace and control. Engage your muscles in new ways to enhance strength, increase joint mobility, and sharpen your focus and body awareness through guided flow sequences. Come flow with us!",
    ],
    others: [
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-6.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-6.svg`,
        heading: "LEARNING",
      },
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-5.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-5.svg`,
        heading: "INGENUITY",
      },
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-9.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-9.svg`,
        heading: "COMMUNITY",
      },
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-4.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-4.svg`,
        heading: "TRANSFORMATION",
      },
    ],
  },
  img15: {
    heading: "Bordeaux à Bangalore",
    eImg: `${process.env.PUBLIC_URL}/images/event/Bordeaux-hover.png`,
    eDetail: [
      {
        title: "Date:",
        desc: "9th January 2025",
      },
      {
        title: "Tour time:",
        desc: "7 PM - 10 PM",
      },
      {
        title: "Venue:",
        desc: "Sadahalli",
      },
      {
        title: "Price:",
        desc: "INR 1,25,000 + GST",
      },
    ],
    highlights: [
      "This private dining experience will be limited to just 8 guests, ensuring an intimate and personalised setting.",
      "The evening will be hosted by a wine expert and sommelier who will guide you through the rich history, unique characteristics, and exceptional vintages of these celebrated wines in an engaging and educational tasting session. ",
    ],

    Overview: [
      "This exclusive event offers an unparalleled opportunity to taste the legendary First Growth Bordeaux wines as classified in the historic 1855 Bordeaux Classification. Revered as the world’s most prestigious wines, these First Growths are the epitome of refinement and luxury.",
      "The evening will begin with an aperitif, setting the stage for what promises to be a sensational journey. Each wine will be thoughtfully presented alongside detailed commentary on the vintage conditions that shaped their character, and their historical significance. The experience will culminate with a culinary masterpiece designed to complement these extraordinary wines. Whether you are a seasoned collector or an aspiring connoisseur, this event offers a rare chance to taste these historic wines.",
      "You will taste:",
    ],
    otherHighlights: [
      "2009 Château Lafite Rothschild: The embodiment of Pauillac’s elegance, known for its finesse, complexity, and aging potential.",
      "2009 Château Latour: A robust, structured wine that showcases Latour’s power and precision.",
      "2009 Château Margaux: Quintessential Margaux, offering an exquisite balance of fragrance, silkiness, and depth.",
      "2009 Château Haut-Brion (Pessac-Léognan): Distinguished by its smoky, earthy character, and impeccable harmony.",
      "2009 Château Mouton Rothschild: A rich, opulent wine that epitomizes Mouton’s flair and innovation",
      "2009 Château d’Yquem: This Premier Cru Supérieur, is a celestial symphony of opulence and precision, embodying the very soul of Sauternes perfection.",
    ],
    others: [
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-6.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-6.svg`,
        heading: "LEARNING",
      },
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-8.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-8.svg`,
        heading: "CONNECTION",
      },
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-9.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-9.svg`,
        heading: "COMMUNITY",
      },
      {
        defaultImg: `${process.env.PUBLIC_URL}/images/essance-1.svg`,
        hoverImg: `${process.env.PUBLIC_URL}/images/essance-1.svg`,
        heading: "EMOTION",
      },
    ],
  },
};
