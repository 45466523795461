import { Heading, Img, Text } from "components";
import React from "react";
import { useState } from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import HoverImage from "components/hoverImage/hoverImg";
export default function EventPopup({
  isOpen = false,
  setIsOpen = () => {},
  handleModalClose = () => {},
  data = {},
}) {
  return (
    <>
      <Modal
        isOpen={isOpen}
        className={"myeoPopup"}
        onRequestClose={handleModalClose}
        contentLabel="Example Modal"
        role="document"
        preventScroll={false}
      >
        <button onClick={handleModalClose} className="btnClose ">
          <Img
            src={`${process.env.PUBLIC_URL}/images/close1.svg`}
            alt="image"
            width="16px"
            height="16px"
          />
        </button>
        <div className="myeopopupBox">
          <div className="aboutBg" style={{ position: "initial" }}>
            <div className="flex flex-col items-center mb-[20px]  md:mb-[0px]  sm:mb-[10px]">
              <h4
                class="yellowHeading text-orange  "
                style={{ marginBottom: "0px" }}
              >
                {data?.heading || ""}
              </h4>
            </div>

            <div className="max-w-[1440px] lg:max-w-[1250px]  positon mb-[50px] md:mb-[30px] md:flex-col mx-auto flex w-full gap-5 self-stretch  z-1">
              <div className="arrowDownLeftRed left">
                <svg
                  width="68"
                  height="192"
                  viewBox="0 0 68 192"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    className="path"
                    clip-rule="evenodd"
                    d="M51.6218 96.3053L65.3647 76.2422H37.8789L51.6218 96.3053Z"
                    stroke="#ECC846"
                    strokeWidth="2"
                  />
                  <path
                    fill-rule="evenodd"
                    className="path"
                    clip-rule="evenodd"
                    d="M51.6218 133.864L65.3647 113.801H37.8789L51.6218 133.864Z"
                    stroke="#ECC846"
                    strokeWidth="2"
                  />
                  <path
                    fill-rule="evenodd"
                    className="path"
                    clip-rule="evenodd"
                    d="M51.6218 171.423L65.3647 151.359H37.8789L51.6218 171.423Z"
                    stroke="#ECC846"
                    strokeWidth="2"
                  />
                  <path
                    fill-rule="evenodd"
                    className="path"
                    clip-rule="evenodd"
                    d="M16.032 114.49L29.7748 94.4268H2.28906L16.032 114.49Z"
                    stroke="#ECC846"
                    strokeWidth="2"
                  />
                  <path
                    fill-rule="evenodd"
                    className="path"
                    clip-rule="evenodd"
                    d="M16.032 152.049L29.7748 131.986H2.28906L16.032 152.049Z"
                    stroke="#ECC846"
                    strokeWidth="2"
                  />
                  <path
                    fill-rule="evenodd"
                    className="path"
                    clip-rule="evenodd"
                    d="M16.032 189.607L29.7748 169.544H2.28906L16.032 189.607Z"
                    stroke="#ECC846"
                    strokeWidth="2"
                  />
                  <path
                    fill-rule="evenodd"
                    className="path"
                    clip-rule="evenodd"
                    d="M51.6218 21.1882L65.3647 1.125H37.8789L51.6218 21.1882Z"
                    stroke="#ECC846"
                    strokeWidth="2"
                  />
                  <path
                    fill-rule="evenodd"
                    className="path"
                    clip-rule="evenodd"
                    d="M51.6218 58.7468L65.3647 38.6836H37.8789L51.6218 58.7468Z"
                    stroke="#ECC846"
                    strokeWidth="2"
                  />
                  <path
                    fill-rule="evenodd"
                    className="path"
                    clip-rule="evenodd"
                    d="M16.032 39.3738L29.7748 19.3105H2.28906L16.032 39.3738Z"
                    stroke="#ECC846"
                    strokeWidth="2"
                  />
                  <path
                    fill-rule="evenodd"
                    className="path"
                    clip-rule="evenodd"
                    d="M16.032 76.9324L29.7748 56.8691H2.28906L16.032 76.9324Z"
                    stroke="#ECC846"
                    strokeWidth="2"
                  />
                </svg>
              </div>

              <div className="arrowDownLeftRed right">
                <svg
                  width="68"
                  height="209"
                  viewBox="0 0 73 209"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className="path2"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M17.2732 104.253L2.30469 126.105H32.2417L17.2732 104.253Z"
                    stroke="#ECC846"
                    strokeWidth="2"
                  />
                  <path
                    className="path2"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M17.2732 63.3447L2.30469 85.1971H32.2417L17.2732 63.3447Z"
                    stroke="#ECC846"
                    strokeWidth="2"
                  />
                  <path
                    className="path2"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M17.2731 22.4375L2.30469 44.2899H32.2416L17.2731 22.4375Z"
                    stroke="#ECC846"
                    strokeWidth="2"
                  />
                  <path
                    className="path2"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M56.0388 84.4463L41.0703 106.299L71.0072 106.299L56.0388 84.4463Z"
                    stroke="#ECC846"
                    strokeWidth="2"
                  />
                  <path
                    className="path2"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M56.0388 43.5391L41.0703 65.3915H71.0072L56.0388 43.5391Z"
                    stroke="#ECC846"
                    strokeWidth="2"
                  />
                  <path
                    className="path2"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M56.0388 2.63086L41.0703 24.4833H71.0072L56.0388 2.63086Z"
                    stroke="#ECC846"
                    strokeWidth="2"
                  />
                  <path
                    className="path2"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M17.2732 186.069L2.30469 207.922H32.2417L17.2732 186.069Z"
                    stroke="#ECC846"
                    strokeWidth="2"
                  />
                  <path
                    className="path2"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M17.2732 145.161L2.30469 167.014L32.2417 167.014L17.2732 145.161Z"
                    stroke="#ECC846"
                    strokeWidth="2"
                  />
                  <path
                    className="path2"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M56.0387 166.262L41.0703 188.114H71.0072L56.0387 166.262Z"
                    stroke="#ECC846"
                    strokeWidth="2"
                  />
                  <path
                    className="path2"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M56.0387 125.354L41.0703 147.206H71.0072L56.0387 125.354Z"
                    stroke="#ECC846"
                    strokeWidth="2"
                  />
                </svg>
              </div>

              <div className="mt-[8px] w-[40%] lg:w-[40%] md:w-[100%]  relative flex items-start self-stretch md:flex-col w-full justify-center gap-x-20">
                <Img
                  src={data?.eImg || ""}
                  alt="image"
                  width="550px"
                  className="object-cover lg:w-auto lg:h-auto  max-w-full"
                />
              </div>

              <div className="mt-[8px] w-[60%] lg:w-[60%] md:w-[100%]  relative flex flex-col items-start self-stretch md:flex-col w-full justify-between gap-x-20 ">
                <div className="PointBox subheadingInner  ">
                  <ul>
                    {data?.eDetail?.map((item, ind) => (
                      <li key={ind}>
                        <div className="w-[40%] lg:w-[40%] text-purple">
                          {" "}
                          {item?.title || ""}
                        </div>
                        <div className=" w-[60%] lg:w-[60%]">
                          {item?.desc || ""}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            <div className="max-w-[1440px] lg:max-w-[1250px]  mx-auto mb-[30px] sm:mt-[30px]  md:gap-[16px]  gap-[46px]  flex flex-col items-start self-stretch md:flex-col w-full justify-between gap-x-20 ">
              <h4 className="text-white-a700  font-averta md:text-[34px]  text-orange  text-[50px] font-bold lg:text-[42px] md:text-[30px] sm:text-[24px]">
                Highlights:
              </h4>
              <ul className="whiteBullet">
                {data?.highlights?.map((highlight, ind) => (
                  <li key={ind}> {highlight} </li>
                ))}
              </ul>
            </div>
            <div className="relative ">
              <div className="max-w-[1440px] lg:max-w-[1250px]  mx-auto flex flex-col   gap-3 self-stretch  z-1">
                {/* <div
                  className="arrowDownLeftRed left"
                  style={{ bottom: "0px", top: "auto" }}
                >
                  <svg
                    width="100"
                    height="383"
                    viewBox="0 0 100 383"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      className="path2"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M17.2732 104.277L2.30469 126.108H32.2417L17.2732 104.277Z"
                      stroke="#ECC846"
                      strokeWidth="2"
                    />
                    <path
                      className="path2"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M17.2732 63.4092L2.30469 85.2403H32.2417L17.2732 63.4092Z"
                      stroke="#ECC846"
                      strokeWidth="2"
                    />
                    <path
                      className="path2"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M56.0369 84.4902L41.0684 106.321L71.0052 106.321L56.0369 84.4902Z"
                      stroke="#ECC846"
                      strokeWidth="2"
                    />
                    <path
                      className="path2"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M56.0369 43.623L41.0684 65.4541H71.0052L56.0369 43.623Z"
                      stroke="#ECC846"
                      strokeWidth="2"
                    />
                    <path
                      className="path2"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M56.0369 2.75488L41.0684 24.586H71.0052L56.0369 2.75488Z"
                      stroke="#ECC846"
                      strokeWidth="2"
                    />
                    <path
                      className="path2"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M17.2732 186.015L2.30469 207.846H32.2417L17.2732 186.015Z"
                      stroke="#ECC846"
                      strokeWidth="2"
                    />
                    <path
                      className="path2"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M17.2732 145.146L2.30469 166.978L32.2417 166.978L17.2732 145.146Z"
                      stroke="#ECC846"
                      strokeWidth="2"
                    />
                    <path
                      className="path2"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M56.0367 166.227L41.0684 188.058H71.0052L56.0367 166.227Z"
                      stroke="#ECC846"
                      strokeWidth="2"
                    />
                    <path
                      className="path2"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M56.0367 125.357L41.0684 147.189H71.0052L56.0367 125.357Z"
                      stroke="#ECC846"
                      strokeWidth="2"
                    />
                  </svg>
                </div> */}

                <h3 className="text-white-a700  font-averta md:text-[34px]  text-orange  text-[50px] font-bold lg:text-[42px] md:text-[30px] sm:text-[24px]">
                  Overview:
                </h3>
                {/* <p className="subheadingInner">Tee off at the beautiful Prestige Golfshire, where the stunning greens overlooking the verdant Nandi Hills and the glorious Bangalore weather provide the perfect setting for an unforgettable day. </p>
                        <p className="subheadingInner">
                            Join your fellow EO members on one of the country’s most prestigious golf courses to forge new friendships, share laughs, and enjoy a leisurely yet spirited game of golf together followed by fellowship at the 19th hole....
                        </p> */}
                {data?.Overview?.map((item, ind) => (
                  <p className="subheadingInner" key={ind}>
                    {item || ""}{" "}
                  </p>
                ))}
                <ul className="yellowBullet">
                  {data?.otherHighlights?.map((highlight, ind) => (
                    <li key={ind}> {highlight} </li>
                  ))}
                </ul>
              </div>
              {/* <div
                className="arrowDownLeftRed right"
                style={{ bottom: "0px", top: "auto" }}
              >
                <svg
                  width="44"
                  height="119"
                  viewBox="0 0 44 119"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className="path2"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.8364 59.1533L2.39062 71.4833H19.2823L10.8364 59.1533Z"
                    stroke="#ECC846"
                    strokeWidth="2"
                  />
                  <path
                    className="path2"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.8364 36.0713L2.39062 48.4012H19.2823L10.8364 36.0713Z"
                    stroke="#ECC846"
                    strokeWidth="2"
                  />
                  <path
                    className="path2"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.8364 12.9902L2.39062 25.3202H19.2823L10.8364 12.9902Z"
                    stroke="#ECC846"
                    strokeWidth="2"
                  />
                  <path
                    className="path2"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M32.7095 47.9775L24.2637 60.3076H41.1554L32.7095 47.9775Z"
                    stroke="#ECC846"
                    strokeWidth="2"
                  />
                  <path
                    className="path2"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M32.7095 24.8965L24.2637 37.2266H41.1554L32.7095 24.8965Z"
                    stroke="#ECC846"
                    strokeWidth="2"
                  />
                  <path
                    className="path2"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M32.7095 1.81445L24.2637 14.1445H41.1554L32.7095 1.81445Z"
                    stroke="#ECC846"
                    strokeWidth="2"
                  />
                  <path
                    className="path2"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.8364 105.317L2.39062 117.647H19.2822L10.8364 105.317Z"
                    stroke="#ECC846"
                    strokeWidth="2"
                  />
                  <path
                    className="path2"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.8364 82.2354L2.39062 94.5653H19.2823L10.8364 82.2354Z"
                    stroke="#ECC846"
                    strokeWidth="2"
                  />
                  <path
                    className="path2"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M32.7095 94.1416L24.2637 106.472H41.1553L32.7095 94.1416Z"
                    stroke="#ECC846"
                    strokeWidth="2"
                  />
                  <path
                    className="path2"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M32.7095 71.0596L24.2637 83.3897H41.1553L32.7095 71.0596Z"
                    stroke="#ECC846"
                    strokeWidth="2"
                  />
                </svg>
              </div> */}
            </div>

            <div className="max-w-[1440px] lg:max-w-[1250px] mx-auto flex w-full flex-col  self-stretch  lg:p-12 md:gap-24 md:p-5 sm:gap-8 pb-14 relative z-1">
              <div className="essence-gap flex justify-center items-center sm:mt-[30px] sm:mb-[0px] md:mt-[30px] md:mb-[0px] mt-[40px] mb-[40px]">
                <span class="EssenceHeadingsmall flex flex-col items-center sm:mb-[0px] mb-[0px] text-white">
                  TURN UP. TUNE IN.
                </span>
              </div>
              <div className="grid grid-cols-2 items-end sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4 essenceSec">
                {data?.others?.map((item, ind) => (
                  <div
                    className="p-4 flex justify-center mb-4 sm:mb-4 "
                    key={ind}
                  >
                    <div className="text-center essenceCol flex flex-col justify-center items-center">
                      <HoverImage
                        defaultImage={item?.defaultImg || ""}
                        hoverImage={item?.hoverImg || ""}
                        altText="Feature Image"
                        className="object-cover lg:w-auto lg:h-auto  max-w-full "
                      />

                      <h4 className="subheadingInner sm:mt-[10px]">
                        {item?.heading || ""}
                      </h4>
                    </div>
                  </div>
                ))}
              </div>

              <div className="AppDownloadButton  mt-10 sm:mt-[0px] flex justify-center items-center">
                <a
                  href="https://eappsapi.vehub.live/eo_2024/api/cli/v1/Webview/page/welcome"
                  target="_blank"
                >
                  {" "}
                  Book on the RIEMIX App
                </a>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
