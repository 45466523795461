import React from "react";
import Routes from "./Routes";
import { BrowserRouter as Router } from "react-router-dom";
import Header from "components/Header";

function App() {
  return (
    <>
      <Router basename="">
        <Header />
        <Routes />
      </Router>
    </>
  );
}

export default App;
